import { Box, Container, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { getTags } from "./ContentLoader"
import PaperRow from "./PaperRow"


const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: "5em"
    }

}))

export default function Home() {
    const classes = useStyles()
    const { t } = useTranslation();

    return (
        <Container maxWidth="lg" className={classes.root}>
            {getTags(t("posts")).map((e, i) => {
                return (
                    <Box m="2em" id={e} key={i}>
                        <PaperRow tag={e} i={i} />
                    </Box>
                )
            })}
        </Container>
    )
}
