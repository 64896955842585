import { Post } from './static/content'

export function getTags(posts: Post[]) {
    return Array.from(new Set(posts.map((e: Post) => e.tags).flat()))
}

export function getPostByTag(posts: Post[], tag: string) {
    return posts.filter((e) => e.tags.includes(tag))
}

export function getPostByID(posts: Post[], id: string) {
    return posts.filter((e) => e.id === id).pop()
}
