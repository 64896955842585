import { Box, Grid, Link, Typography, makeStyles } from "@material-ui/core"
import { AppBar, Toolbar } from "@material-ui/core"
import PetsOutlinedIcon from '@material-ui/icons/PetsOutlined';
import { getTags } from "./ContentLoader";
import { Link as RouteLink } from "react-router-dom";
import Local from "./Local";
import { useTranslation } from "react-i18next";
import { HashLink } from 'react-router-hash-link';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        padding: "1em"
    },
} as const))

export default function Nav() {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <AppBar position="sticky" elevation={3} color="inherit">

            <Toolbar className={classes.toolbar}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={12} sm={6}  >
                        <Link component={RouteLink} to="/" color="inherit" >
                            <Typography variant="h5" align="center" color="secondary"><PetsOutlinedIcon fontSize="small" /> Yum Cat
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm>
                        <Grid container justify="center">
                            {getTags(t("posts")).map((e: string) => {
                                return (
                                    <Box m={1} key={e}>
                                        <Link component={HashLink} to={`/#${e}`} >
                                            <Typography variant="subtitle1" display="inline">{e}</Typography>
                                        </Link>
                                    </Box>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Local />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar >
    )

}
