import { Button } from "@material-ui/core"
import TranslateIcon from '@material-ui/icons/Translate';
import React from "react";
import { useTranslation } from "react-i18next";


export default function Local(props: any) {
    const [refresh, setRefresh] = React.useState([])
    const { t, i18n } = useTranslation();

    const handleClick = () => {
        if (i18n.language === "en") {
            i18n.changeLanguage('ca')
        } else {
            i18n.changeLanguage('en')
        }
        setRefresh([])
    }


    return (
        <Button onClick={handleClick}><TranslateIcon />{t("toggleLang")}{refresh}</Button>
    )
}
