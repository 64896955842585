import { Box, Grid, Paper, Typography, makeStyles, Grow } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import CardPreview from "./CardPreview"
import { getPostByTag } from "./ContentLoader"


const useStyles = makeStyles((theme) => ({
    rowHeader: {
        backgroundColor: theme.palette.secondary.main,
        height: "100%",
        width: "100%",
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
        display: "flex",
        minHeight: "5em"
    },

} as const))

export default function PaperRow(props: { tag: string, i: number }) {
    const classes = useStyles()
    const { t } = useTranslation();

    return (
        <Grow in={true} {...({ timeout: (props.i + 1) * 400 })}>
            <Paper elevation={3}>
                <Grid container>
                    <Grid item sm={2} xs={12}>
                        <Box className={classes.rowHeader} >
                            <Typography color="textSecondary">{props.tag}</Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        sm={10}
                        container
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                    >
                        {getPostByTag(t("posts"), props.tag).map((e, i) => {
                            return (
                                <Box m="1em" key={i}>
                                    <CardPreview post={e} i={i} />
                                </Box>
                            )
                        })}
                    </Grid>
                </Grid>
            </Paper >
        </Grow>
    )
}
