import { ButtonBase, Card, CardActions, CardContent, CardHeader, CardMedia, Grid, Grow, makeStyles, Typography } from "@material-ui/core"
import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouteLink } from "react-router-dom";
import { Post } from "./static/content"


const useStyles = makeStyles((theme) => ({
    previewCard: {
        backgroundColor: "gray",
        width: "20em"
    },
    cardMedia: {
        height: "10em"
    }

}))

export default function CardPreview(props: { post: Post, i: number }) {
    const classes = useStyles()
    const { t } = useTranslation();

    return (
        <Grid item>
            <Grow in={true} {...({ timeout: (props.i + 1) * 1000 })}>
                <ButtonBase component={RouteLink} to={{
                    pathname: "/post",
                    state: { post: props.post }
                }}>
                    <Card className={classes.previewCard}>
                        <CardHeader title={props.post.title} />
                        <CardMedia image={props.post.media} className={classes.cardMedia} />
                        <CardContent>
                            <Typography>
                                {props.post.subtitle}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Typography variant="button" color="primary">{t("readMore")}</Typography>
                        </CardActions>
                    </Card>
                </ButtonBase>
            </Grow>
        </Grid>
    )
}
