import React from 'react';
import './App.css';
import { CssBaseline } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import Nav from './Nav';
import Home from './Home';
import PostPage from './PostPage';
import ScrollToTop from './ScrollToTop';
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";


export default function App() {
  let location = useLocation();

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'dark',
          primary: {
            main: '#F2E749',
          },
          secondary: {
            main: '#F26A1B',
          },
          background: {
            default: '#212121',
            paper: '#424242',
          },
        },
      }),
    [],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Nav />
      <ScrollToTop />
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="fade"
          timeout={300}
        >
          <Switch location={location}>
            <Route path="/post" component={PostPage} />
            <Route path="/" component={Home} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </ThemeProvider>
  );
}
