import i18n from "i18next";
import { ca } from './static/ca'
import { Post } from "./static/content";
import { en } from "./static/en";

i18n.init({
    lng: "en",
    resources: {
        en: {
            translations: {
                toggleLang: "En català",
                posts: en as Post[],
                readMore: "Read More...",
                steps: "Steps",
                step: "Step",
                ingredients: "Ingredients",
            }
        } as const,
        ca: {
            translations: {
                toggleLang: "In English",
                posts: ca as Post[],
                readMore: "Llegeix més...",
                steps: "Passos",
                step: "Pas",
                ingredients: "Ingredients Alimentaris",
            }
        } as const,
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    returnObjects: true,

    react: {
        wait: true
    }
});

export default i18n;