import Escudella from './Escudella.jpg'
import Pastissets from './Pastissets.jpg'
import Aioli from './Allioli.jpg'
import Pa from './Pa.jpg'
import Carquinyolis from './Carquinyolis.jpg'
import Romesco from './Romesco.jpg'

export const ca = [
    {
        "id": "0",
        "title": "Escudella i carn d'olla",
        "subtitle": "Deliciós plat",
        "media": Escudella,
        "description": "",
        "ingredients": [
            "2 peus de pollastre, pelats o 1 coll de gallina",
            "1 molleig de pollastre",
            "1 peu i turmell de xai",
            "1 canya de xai de 1/3 lliures 1 os de pernil (aproximadament 2 lliures) o un tros de pernil de 1/4",
            "de lliura amb el seu greix",
            "2 lliures d’ossos de vedella o vedella de porc (sopa)",
            "5 quarts d’aigua",
            "1 llesca de pa franc ranci, escorça eliminada",
            "1 ou gran, batut",
            "1/2 lliura de porc mòlt Botifarra de",
            "1/2 lliura, retirada la carcassa i carn esmicolada",
            "2 grans d'all, pelats i picats finament",
            "1/4 tassa de fulles de julivert fresc picat finament Sal i pebre negre acabat de moldre al gust",
            "1/4 de tassa de pa ratllat fresc, si cal",
            "1 patata (aproximadament 1/2 lliura), pelada i tallada a trossos",
            "1 pastanaga, tallada",
            "1 lliura de bleda suïssa, les tiges retirades i les fulles picades Farina per a dragatge",
            "Fideos de pasta o arròs"
        ],
        "steps": [
            "Col·loqueu els peus o el coll de pollastre, la molleja de pollastre, el peu i el turmell de xai, la canya de xai, l’os de pernil o un tros de pernil, els ossos de vedella o de medul·la de porc (sopa) en una olla gran. Cobriu-ho amb l'aigua i deixeu-ho bullir. Bullir durant 2 hores, donant voltes a la carn de tant en tant. Reposeu l’aigua si cal. ",
            "Mentrestant, feu la mandonguilla gran. Remull la llesca de pa amb aigua. Traieu l’aigua i poseu-la en un bol de mida mitjana amb l’ou batut. Afegiu el porc mòlt, la butifarra, els alls i el julivert i salpebreu-ho. Si la mandonguilla no es manté bé, afegiu-hi una mica de pa ratllat. Formeu la carn en una sola bola gran i reserveu-la a la nevera.",
            "Després que els ossos de la carn hagin bullit durant 2 hores, apagueu el foc i traieu-los. Traieu la medul·la dels ossos de la sopa i la carn del fust de xai, talleu-la i rebutgeu-ne tots els ossos. Transferiu la medul·la i la carn a una cassola o a una cassola gran. Colar el brou i afegir-lo a la cassola del guisat.",
            "Feu-ho bullir i afegiu-hi la pasta (o l’arròs) patata, la pastanaga i les bledes. Condimentar al gust amb sal i bullir durant 10 minuts. Dragueu uniformement la mandonguilla amb farina, eliminant-ne l'excés, afegiu-la al brou i bulliu-la fins que quedi ferma, uns 20 a 25 minuts, reposant l'aigua si cal. Serviu immediatament. La mandonguilla es pot dividir per cada comensal."
        ],
        "ending": "",
        "tags": [
            "Saborós"
        ]
    },
    {
        "id": "1",
        "title": "Pastissets",
        "subtitle": "Dolços clàssics",
        "media": Pastissets,
        "description": "",
        "ingredients": [
            "500 g de farina normal ",
            "1 culleradeta de pols de coure ",
            "75 ml d’oli d’oliva ",
            "100 ml d’anís o qualsevol licor amb gust d’anís ",
            "100 ml de vi dolç ",
            "75 g de mantega sense sal, estovada ",
            "10 cullerades soperes de cabell d'àngel (melmelada de carbassa) ",
            "1 ou gran, batut lleugerament sucre llustre, per ruixar"
        ],
        "steps": [
            "Tamiseu la farina i la pols de coure en un bol i feu un pou al centre. Barregeu junts l’oli, l’anís i el vi dolç i aboqueu-lo al pou amb la mantega estovada. Reuneix-ho i amassa-ho durant 10 minuts fins obtenir una massa fina i elàstica que no quedi enganxosa. Deixar reposar 20 minuts. Preescalfeu el forn a 200 ° C i folreu una safata de forn amb pergamí de forn.",
            "Dividiu la massa en boles de 10 x 80 g. Estireu-los (sense farina addicional) en cercles de 12 cm.",
            "Col·loqueu una generosa cullerada de cabell d’àngel al centre de cada cercle de massa. Raspalleu les vores amb ou batut, doblegueu-les per fer una mitja lluna i premeu per tancar, segellant amb una forquilla. Poseu-los a la safata del forn, raspalleu amb l’ou batut. Coure al forn durant 18-20 minuts fins que estigui daurat. Es refreda una mica i es recobreix amb sucre i se serveix calent."
        ],
        "ending": "",
        "tags": [
            "Menjar dolç"
        ]
    },
    {
        "id": "2",
        "title": "Aioli",
        "subtitle": "Sabor increïble",
        "media": Aioli,
        "description": "",
        "ingredients": [
            "4 grans d'all, pelats ",
            "Una mica de sal ",
            "Suc de llimona fresca ",
            "Oli d’oliva verge extra espanyol"
        ],
        "steps": [
            "Pas 1 Col·loqueu els alls en un morter juntament amb la sal. ",
            "Amb un pistil, tritureu els grans d'all fins que quedi una pasta suau. ",
            "(La sal impedeix que els alls rellisquin al fons del morter a mesura que els batreu.) Afegiu el suc de llimona als alls. ",
            "Gota a gota, aboqueu l’oli d’oliva al morter lentament mentre continueu aixafant la pasta amb el vostre morter. ",
            "Seguiu girant la mà en un moviment circular lent i continu al morter. ",
            "El degoteig ha de ser lent i constant. ",
            "Assegureu-vos que la pasta absorbeixi l’oli d’oliva a mesura que aneu. ",
            "Seguiu afegint l’oli, gota a gota, fins que tingueu la consistència d’una maionesa molt espessa. ",
            "Si el vostre allioli es fa massa dens, afegiu aigua per diluir-lo. ",
            "Això necessita temps (uns 20 minuts de càmera lenta al voltant del morter) per crear una salsa densa i rica."
        ],
        "ending": "",
        "tags": [
            "Salses"
        ]
    },
    {
        "id": "3",
        "title": "Pa amb tomàquet",
        "subtitle": "Pa increïble",
        "media": Pa,
        "description": "",
        "ingredients": [
            "8 rodanxes grans de pa a la campanya, d'uns 1/2 polzada de gruix ",
            "4 grans d'all (opcional) ",
            "2 tomàquets molt madurs, reduïts a la meitat a la meitat ",
            "Oli d’oliva verge extra espanyol ",
            "Sal marina gruixuda ",
            "Pebre acabat de moldre",
        ],
        "steps": [
            "Enceneu una graella. Quan el foc estigui mig calent, feu el pa a la planxa durant 2 minuts per costat o fins que estigui ben daurat i cruixent. ",
            "Doneu a cada comensal un gra d’all i mig tomàquet. Feu-los fregar les torrades amb l’all i després amb el tomàquet tallat. (La idea és aplicar una fina pel·lícula de tomàquet al pa.) A continuació, poden regar les torrades amb oli d'oliva, escampar-hi sal i pebre i menjar.",
        ],
        "ending": "",
        "tags": [
            "Saborós"
        ]
    },
    {
        "id": "4",
        "title": "Carquinyolis",
        "subtitle": "Cruixent",
        "media": Carquinyolis,
        "description": "",
        "ingredients": [
            "3 ous",
            "1 tassa de sucre de pastissers",
            "1 tassa de farina",
            "1 cda. anís mòlt",
            "1 cda. de canyella mòlta",
            "1 1⁄4 culleradeta. llevat sec actiu",
            "Ratlladura ratllada de 1/2 llimona",
            "1 1⁄4 tassa d’ametlles torrades",
        ],
        "steps": [
            "Preescalfeu el forn a 375 °. Folreu una paella de fulls amb paper sulfuritzat i reserveu-la. Batre 1 dels ous en un bol petit i reservar.",
            "Poseu sucre, farina, anís, canyella, llevat i ratlladura de llimona al bol d’una batedora de peu equipada amb el conjunt de la pala i barregeu-la bé. Batre els ous restants, 1 a la vegada, a gran velocitat, barrejant bé després de cada addició. Traieu el bol de la batedora i incorporeu-hi les ametlles.",
            "Gireu la massa sobre una superfície ben enfarinada i dividiu-la per la meitat. Enfarineu lleugerament les mans i doneu forma a la massa en 2 cilindres lleugerament aplanats d’uns 3 ”d’amplada x 14” de llarg. Retireu l'excés de farina amb un pinzell de pasta seca. Transferiu amb cura els cilindres de la massa a la planxa preparada a uns 4 ″ de distància, i després raspalleu-ho tot amb ou batut. Enforneu-ho, embolseu la massa i fornigueu-ho amb aigua d’una ampolla de polvorització cada 10 minuts aproximadament, fins que l’escorça sigui brillant i marró fosc, uns 30 minuts.",
            "Traieu del forn i deixeu refredar lleugerament; tallar el biaix en galetes gruixudes de 1⁄2 \".Passeu els biscotti a una vinera per deixar - los refredar completament.Les galetes es conserven en un recipient hermètic durant 2 - 3 dies.",
        ],
        "ending": "",
        "tags": [
            "Menjar dolç"
        ]
    },
    {
        "id": "5",
        "title": "Romesco",
        "subtitle": "Àcid",
        "media": Romesco,
        "description": "",
        "ingredients": [
            "Un pot de 16 unces de pebrots vermells escalivats, escorreguts",
            "½ tassa d’ametlles torrades o crues (sense sal)",
            "¼ tassa de tomàquets secs envasats en oli, esbandits i escorreguts",
            "2 grans d’all de mida mitjana a gran, pelats i trinxats",
            "1 cullerada de vinagre de xerès o vingar de vi negre",
            "1 culleradeta de pebre vermell fumat",
            "½ culleradeta de sal marina fina, al gust",
            "¼ culleradeta de pebre de caiena",
            "½ tassa d’oli d’oliva verge extra",
        ],
        "steps": [
            "En una batedora (preferiblement) o processador d’aliments, combineu-ho tot menys l’oli d’oliva. Fixa la tapa i mescla de manera segura, començant a baix i augmentant la velocitat a mesura que puguis guanyar tracció.",
            "Un cop barrejats els ingredients, comenceu a regar amb l’oli d’oliva mentre feu servir la batedora. Barregeu fins aconseguir la consistència desitjada (la meva salsa romesco m'agrada bastant cremosa, però és possible que la preferiu amb una textura més grollera).",
            "Tasteu-ho i afegiu-hi sal addicional (fins a ¼ culleradeta) si encara no us elimina els mitjons. Serviu-lo immediatament o guardeu-lo en un pot a la nevera de 7 a 10 dies.",
        ],
        "ending": "",
        "tags": [
            "Salses"
        ]
    },
]