import Escudella from './Escudella.jpg'
import Pastissets from './Pastissets.jpg'
import Aioli from './Allioli.jpg'
import Pa from './Pa.jpg'
import Carquinyolis from './Carquinyolis.jpg'
import Romesco from './Romesco.jpg'

export const en = [
    {
        "id": "0",
        "title": "Escudella i carn d'olla",
        "subtitle": "Delicious dish",
        "media": Escudella,
        "description": "",
        "ingredients": [
            "2 chicken feet, skinned or 1 chicken neck",
            "1 chicken gizzard",
            "1 lamb foot and ankle",
            "1 1/3 pounds lamb shank",
            "1 ham bone (about 2 pounds) or 1/ 4-pound piece ham with its fat",
            "2 pounds beef or pork marrow (soup) bones",
            "5 quarts water",
            "1 slice stale French bread, crust removed",
            "1 large egg, beaten",
            "1/2 pound ground pork",
            "1/2 pound botifarra, casing removed and meat crumbled",
            "2 garlic cloves, peeled and finely chopped",
            "1/4 cup finely chopped fresh parsley leaves",
            "Salt and freshly ground black pepper to taste 1/4 cup fresh bread crumbs, if needed",
            "1 potato (about 1/ 2 pound), peeled and cut into chunks",
            "1 carrot, cut up",
            "1 pound Swiss chard, stems removed and leaves chopped",
            "Flour for dredging",
            "Fideos pasta or rice"
        ],
        "steps": [
            "Place the chicken feet or neck, chicken gizzard, lamb foot and ankle, lamb shank, ham bone or piece of ham, beef or pork marrow(soup) bones in a large stew pot.Cover with the water and bring to a boil.Boil for 2 hours, turning the meat occasionally.Replenish the water if necessary.",
            "Meanwhile, make the large meatball. Soak the slice of bread in water. Squeeze the water out and lace in a medium - size mixing bowl with the beaten egg.Add the ground pork, butifarra, garlic,and parsley and season with salt and pepper.If the meatball doesn't hold together well add some of the bread crumbs.Form the meat into a single large ball and set aside in the refrigerator.",
            "After the meat bones have been boiling for 2 hours, turn the heat off and remove the bones. Remove the marrow from the soup bones and the meat from the lamb shank, cut it up, and discard all the bones.Transfer the marrow and meat to a stew pot or large casserole.Strain the broth and add it to the stew pot.",
            "Bring to a boil and add the pasta (or rice)potato, carrot, and Swiss chard. Season to taste with salt and boil for 10 minutes.Dredge the meatball evenly in flour, tapping off any excess, add it to the broth and boil until firm, about 20 to 25 minutes, replenishing the water if necessary.Serve immediately.The meatball can be divided by each diner."
        ],
        "ending": "",
        "tags": [
            "Savory"
        ]
    },
    {
        "id": "1",
        "title": "Pastissets",
        "subtitle": "Classic sweets",
        "media": Pastissets,
        "description": "",
        "ingredients": [
            "500g plain flour ",
            "1 teaspoon baking powder ",
            "75ml olive oil ",
            "100ml anise or any aniseed-flavoured liqueur ",
            "100ml sweet wine ",
            "75g unsalted butter, softened ",
            "10 heaped tablespoons cabello de ángel, (pumpkin jam) ",
            "1 large egg, lightly beaten ",
            "caster sugar, to sprinkle"
        ],
        "steps": [
            "Sift the flour and baking powder into a bowl and make a well in the centre. Mix the oil, anise and sweet wine together and pour into the well with the softened butter. Bring together and knead for 10 minutes until you get a fine and elastic dough that isn’t sticky. Leave to stand for 20 minutes. Preheat the oven to 200°C and line a baking tray with baking parchment.",
            "Divide the dough into 10 x 80 g balls. Roll them out (without extra flour) into 12 cm circles.",
            "Spoon a generous tablespoon of cabello de ángel into the middle of each circle of dough. Brush the edges with beaten egg, fold over to make a half moon and press to close, sealing with a fork.",
            "Put them on the baking tray, brush with the beaten egg. Bake for 18–20 minutes until golden.",
            "Cool a little then coat with sugar and serve warm."
        ],
        "ending": "",
        "tags": [
            "Sweets"
        ]
    },
    {
        "id": "2",
        "title": "Aioli",
        "subtitle": "Amazing flavour",
        "media": Aioli,
        "description": "",
        "ingredients": [
            "4 garlic cloves, peeled ",
            "Pinch of salt ",
            "Fresh lemon juice ",
            "Spanish extra-virgin olive oil"
        ],
        "steps": [
            "Place the garlic in a mortar along with the salt. Using a pestle, smash the garlic cloves to a smooth paste. (The salt stops the garlic from slipping at the bottom of the mortar as you pound it down.)",
            "Add the lemon juice to the garlic. Drop by drop, pour the olive oil into the mortar slowly as you continue to crush the paste with your pestle.",
            "Keep turning your pestle in a slow, continuous circular motion in the mortar.",
            "The drip needs to be slow and steady.",
            "Make sure the paste soaks up the olive oil as you go.",
            "Keep adding the oil, drop by drop, until you have the consistency of a very thick mayonnaise.",
            "If your allioli gets too dense, add water to thin it out.",
            "This takes time—around 20 minutes of slow motion around the mortar—to create a dense, rich sauce.",
            "Si el vostre allioli es fa massa dens, afegiu aigua per diluir-lo. ",
            "Això necessita temps (uns 20 minuts de càmera lenta al voltant del morter) per crear una salsa densa i rica."
        ],
        "ending": "",
        "tags": [
            "Sauces"
        ]
    },
    {
        "id": "3",
        "title": "Pa amb tomàquet",
        "subtitle": "Amazing bread",
        "media": Pa,
        "description": "",
        "ingredients": [
            "8 large slices country-style bread, about 1/2 inch thick",
            "4 garlic cloves (optional)",
            "2 very ripe tomatoes, halved crosswise",
            "Spanish extra-virgin olive oil",
            "Coarse sea salt",
            "Freshly ground pepper",
        ],
        "steps": [
            "Light a grill. When the fire is medium hot, grill the bread for 2 minutes per side, or until nicely browned and crisp.",
            "Give each diner a garlic clove and half a tomato. Have them rub their toast with the garlic, then with the cut tomato. (The idea is to apply a thin film of tomato on the bread.) Then they can drizzle their toast with olive oil, sprinkle on some salt and pepper and eat.",
        ],
        "ending": "",
        "tags": [
            "Savory"
        ]
    },
    {
        "id": "4",
        "title": "Carquinyolis",
        "subtitle": "Crunchy",
        "media": Carquinyolis,
        "description": "",
        "ingredients": [
            "3 eggs",
            "1 cup confectioners' sugar",
            "1 cup flour",
            "1 tbsp. ground anise",
            "1 tbsp. ground cinnamon",
            "1 1⁄4 tsp. active dry yeast",
            "Grated zest of 1/2 lemon",
            "1 1⁄4 cups almonds, toasted",
        ],
        "steps": [
            "Preheat oven to 375°. Line a sheet pan with parchment paper and set aside. Beat 1 of the eggs in a small bowl and set aside.",
            "Put sugar, flour, anise, cinnamon, yeast, and lemon zest into the bowl of a standing mixer fitted with the paddle attachment and mix well. Beat in remaining eggs, 1 at a time, on high speed, mixing well after each addition. Remove bowl from mixer and stir in almonds.",
            "Turn dough out onto a well-floured surface and divide in half. Lightly flour your hands and shape dough into 2 slightly flattened cylinders about 3″ wide x 14″ long. Brush off any excess flour with a dry pastry brush. Carefully transfer dough cylinders to prepared sheet pan about 4″ apart, then brush all over with beaten egg. Bake, misting dough and oven with water from a spray bottle every 10 minutes or so, until crust is shiny and dark brown, about 30 minutes.",
            "Remove from oven and let cool slightly; slice on the bias into 1⁄2“-thick cookies. Transfer biscotti to a wine rack to let cool completely. Cookies keep in an airtight container for 2-3 days.",
        ],
        "ending": "",
        "tags": [
            "Sweets"
        ]
    },
    {
        "id": "5",
        "title": "Romesco",
        "subtitle": "Sour",
        "media": Romesco,
        "description": "",
        "ingredients": [
            "One 16-ounce jar of roasted red peppers, drained",
            "½ cup raw or roasted almonds (unsalted)",
            "¼ cup oil-packed sun-dried tomatoes, rinsed and drained",
            "2 medium-to-large cloves garlic, peeled and quartered",
            "1 tablespoon sherry vinegar or red wine vingar",
            "1 teaspoon smoked paprika",
            "½ teaspoon fine sea salt, to taste",
            "¼ teaspoon cayenne pepper",
            "½ cup extra-virgin olive oil",
        ],
        "steps": [
            "In a blender (preferably) or food processor, combine everything but the olive oil. Securely fasten the lid and blend, starting on low and increasing the speed as you are able to gain traction.",
            "Once the ingredients are mostly blended, start drizzling in the olive oil while running the blender. Blend until you reach your desired consistency (I like my romesco sauce pretty creamy, but you might prefer it with a more chunky texture).",
            "Taste, and add additional salt (up to ¼ teaspoon) if it doesn’t quite knock your socks off yet. Serve immediately, or store in a jar in the refrigerator for 7 to 10 days.",
        ],
        "ending": "",
        "tags": [
            "Sauces"
        ]
    },
]