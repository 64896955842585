
import { Box, Card, Chip, Container, Grid, Typography, makeStyles, Grow } from "@material-ui/core"
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import { getPostByID } from "./ContentLoader";
import { Post } from "./static/content";


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "1em",
        marginBottom: "5em",
    },
    chip: {
        margin: ".5em",
    }

}))

export default function PostPage(props: { location: { state: { post: Post } } }) {
    const classes = useStyles()
    const { t } = useTranslation();
    const post = () => {
        return getPostByID(t("posts"), props.location.state?.post.id)
    }

    return (
        <Container className={classes.root} maxWidth="md">
            <Grow in={true}>
                {post() && post() !== undefined ?
                    <Card>
                        <Box my={5} mx="10%">
                            <Typography variant="h3" align="center" color="secondary">
                                {post()?.title}
                            </Typography>
                        </Box>
                        <Box my={2} mx="10%">
                            <Typography variant="h6" align="center">
                                {post()?.subtitle}
                            </Typography>
                        </Box>
                        <Box mb={3}>
                            <Grid container justify="center">
                                {post()?.tags.map((e) => {
                                    return (<Chip className={classes.chip} label={e} key={e} />)
                                })}
                            </Grid>
                        </Box>

                        <Box my={5}>
                            <Grid container justify="center">
                                <img src={post()?.media} alt="Post" width="1024px" />
                            </Grid>
                        </Box>


                        <Box mx="10%" my={5}>
                            <Typography variant="body1">
                                {post()?.description}
                            </Typography>
                        </Box>

                        <Box mx="10%" my={5}>
                            <Typography variant="h5" gutterBottom color="secondary">{t("ingredients")}</Typography>
                            {post()?.ingredients.map((e) => {
                                return (<Box m={3} key={e}><DoneOutlineIcon /><Typography variant="overline">  {e}</Typography></Box>)
                            })}

                        </Box>

                        <Box mx="10%" my={5}>
                            <Typography variant="h5" gutterBottom color="secondary">{t("steps")}</Typography>
                            {post()?.steps.map((e, i) => {
                                return (
                                    <Box my={3} key={e}>
                                        <Typography variant="subtitle1" gutterBottom color="secondary">{t("step")} {i + 1}</Typography>
                                        <Typography variant="body1">{e}</Typography>
                                    </Box>
                                )
                            })}
                        </Box>

                        <Box mx="10%" my={5}>
                            <Typography variant="body1">
                                {post()?.ending}
                            </Typography>
                        </Box>
                    </Card> : <Redirect to="/" />}

            </Grow>
        </Container>
    )

}
